.notification-show {
    padding: 10px;
    background-color: rgb(232, 192, 196);
    width: 100%;
    border-radius: 10px;
}

.notify-user-image {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: fill;
}

.noti-title {
    font-size: 17px;
}

.noti-message {
    font-size: 14px;
}

.noti-time {
    font-size: 12px;
    font-weight: 500;
    background-color: lightskyblue;
    border-radius: 20px;
    padding: 1px 10px;
    width: fit-content;
}

.dashboard-dropdown {
    background-color: rgba(37, 45, 71, 1);
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(37, 45, 71, 1);
    width: 100%;
    min-width: 320px;
    padding: 1px;
}

.noti-count {
    font-size: 10px;
}

.menu-content .menu-item {
    background-color: #8794ef;
    color: black;
}

.menu-item::-webkit-scrollbar-thumb {
    background-color: rgb(43, 52, 83);
    border-radius: 100px;
}

.menu-item::-webkit-scrollbar {
    scrollbar-width: thin;
}

@media (max-width: 991px) {
    .dropdown-mobile[data-bs-popper] {
        left: -230px;
    }
}

@media (max-width: 768px) {
    .dashboard-dropdown {
        min-width: 275px;
    }
}

@media (max-width: 494px) {
    .dropdown-mobile[data-bs-popper] {
        left: -150px;
    }
}
