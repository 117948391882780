.notification {
    display: flex;
    padding: 4px 4px 16px 4px;
    background-color: #374368;
    border-bottom: 1px solid #98A2B3FF;
}

.notification:last-child {
    border-bottom: none;
}

.notification__read {
    opacity: 0.5;
}

.notification__avatar {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification__avatar img {
    object-fit: cover;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.notification__content {
    display: flex;
    flex-direction: column;
}

.notification__content__heading {
    display: flex;
    align-items: center;
    word-break: break-word;
}

.notification__content__heading__name {
    margin-right: 8px;
    color: #223444FF;
    display: inline-block;
    width: fit-content;
}

.notification__content__heading__time {
    color: #98A2B3FF;
}

.notification__message-link {
    color: #8794ef;
    text-decoration: none;
}

.notification__message-text {

}