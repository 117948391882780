.master-class-lessons__wrapper {
    background-color: rgba(28, 34, 55, 1);
    border-radius: 10px;
}

.master-class-lesson__card {
    border-radius: 10px;
    background-color: rgba(28, 34, 55, 1);
    border: none!important;
}

.master-class-lesson__card:hover {
    background-color: #3d4b7a;
}

.master-class-lesson__card-thumbnail {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.master-class-lesson__card__content {
    margin-left: 1rem;
}

.master-class__image {
    margin-left: 3rem;
}

.master-class__image img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.master-class__comments {
    margin-left: 3rem;
}

.master-class__speaker-info {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 10px;
    height: max-content;
    margin-left: 3rem;
}

.speaker-bio {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.speaker-bio.expanded {
    -webkit-line-clamp: unset;
    overflow: visible;
}

.show-more-btn {
    background: none;
    border: none;
    color: #606060;
    cursor: pointer;
    margin-top: 5px;
    padding: 0;
}

.masterclasses-scroll-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: auto;
}

.masterclasses-scroll-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media (max-width: 991px) {
    .master-class__comments {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .master-class-lesson__card__content {
        margin-left: 0;
    }
    .master-class__image {
        margin-left: 0;
    }
    .master-class__speaker-info {
        margin-left: 0
    }
    .master-class__comments {
        margin-left: 0;
    }
}